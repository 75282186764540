import React from "react";

import "./style.column.scss";

const Column = ({children, className}) => {
    const classes = ['hudl-column', className];
    return (
        <div className={classes.join(" ")}>
            {children}
        </div>
    );
};

Column.defaultProps = {
    type: "column"
};

export default Column;
