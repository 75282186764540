import React, {useState} from 'react'
import Button from '@components/atoms/Button'
import Container from '@components/atoms/Container'
import Image from '@components/atoms/Image'
import MarkdownField from '@components/atoms/MarkdownField'
import ContentBlockText from '@components/molecules/ContentBlockText'
import textProcessor from '@helpers/mikanTextSplitter.js';
import notchHelper from '@helpers/notches'
import Observer from '@researchgate/react-intersection-observer'
import PropTypes from 'prop-types'
import { StickyContainer, Sticky } from 'react-sticky'

import './style.scroller-block.scss';

const ScrollerBlocks = ({
    title,
    content,
    buttonBlockOptional: buttonBlock,
    scrollerBlocksList,
    spacing,
    notches,
    lang
}) => {
    const [currentIndex, setCurrentIndex] = useState(1);

    let windowHeight = 0;

    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes = ["hudl-scroller-block", "hudl-padding-top--xxl", "hudl-padding-x--none", marginTopClass, marginBottomClass];
    const hasButton = buttonBlock && buttonBlock.buttonUrl && buttonBlock.buttonContent;
    const progress = `${currentIndex * 100 / scrollerBlocksList.length}%`

    const {NotchLayout} = notchHelper({notches, classArray: classes})

    if (typeof window !== 'undefined') {
        windowHeight = window.innerHeight;
    }

    const handleSlideChange = (event, index) => {
        if (event.isIntersecting === true) {
            setCurrentIndex(index + 1);
        }
    }

    // Create list of Scroller Block List items.
    const scrollerBlocksListItems = scrollerBlocksList.map((item, index) => {
        const hasImage = item.image && item.image.image;
        const threshold = [0.5];
        return (
            <Observer rootMargin="-30% 0px 0px 0px" threshold={threshold} onChange={(event) => handleSlideChange(event, index)}>
                <article key={index.toString()} className="hudl-scroller-block__slide">
                    <div className="hudl-scroller-block__info">
                        <ContentBlockText
                            lang={lang}
                            pretitle={item.pretitle}
                            title={item.title}
                            content={item.content}
                            buttonBlock={item.buttonBlockOptional}
                            trackingComponent="scroller_block_list_item"
                        />
                    </div>
                    <div className="hudl-scroller-block__image">
                        { hasImage && <Image imgsrc={item.image.image} title={item.image.imageTitle} alt={item.image.imageAlt} /> }
                    </div>
                </article>
            </Observer>
        )
    });

    const scrollerBlockImages = scrollerBlocksList.map((item, index) => {
        const hasImage = item.image && item.image.image;
        const isActive = index + 1 === currentIndex;
        let className = "hudl-scroller-block__fixed-image";

        if (isActive) {
            className += " hudl-scroller-block__fixed-image--active"
        }

        return (
            <div className={className}>
                <div className="hudl-scroller-block__slide-number">{`0${index + 1}`}</div>
                { hasImage && <Image imgsrc={item.image.image} title={item.image.imageTitle} alt={item.image.imageAlt} /> }
            </div>
        )
    })

    return (
        <div className={classes.join(" ")}>
            { notches && (
                <NotchLayout notchesOptions={notches} />
            ) }
            <div className="hudl-scroller-block__intro-block hudl-margin-bottom--xxl">
                <Container size="small">
                    <h2 className="hudl-scroller-block__intro-title hudl-beta-type">{textProcessor(lang, title)}</h2>
                    <MarkdownField content={content} />
                    { hasButton && <Button component="scoller_block" variant={buttonBlock.buttonStyle} to={buttonBlock.buttonUrl} className="hudl-button" newPage={buttonBlock.newPage}>{textProcessor(lang, buttonBlock.buttonContent)}</Button> }
                </Container>
            </div>
            <Container size="large">
                <StickyContainer>
                    <Sticky disableCompensation bottomOffset={windowHeight} topOffset={-windowHeight * .25}>
                        {({style, isSticky}) => (
                            <div>
                                <div style={{...style, marginTop: isSticky ? '25vh': 0}} className="hudl-scroller-block__progress">
                                    <div style={{height: progress}} className="hudl-scroller-block__progress-inner" />
                                    <span>{`${currentIndex} / ${scrollerBlocksList.length}`}</span>
                                </div>
                                <div style={{...style, marginTop: isSticky ? '25vh': 0, marginLeft: isSticky ? '444px' : ''}} className="hudl-scroller-block__images">
                                    {scrollerBlockImages}
                                </div>
                            </div>
                        )}
                    </Sticky>
                    <div className="hudl-scroller-block__slider">
                        {scrollerBlocksListItems}
                    </div>
                </StickyContainer>
            </Container>
        </div>
    )
}

export default ScrollerBlocks

ScrollerBlocks.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,   // Markdown.
    buttonBlock: PropTypes.shape({
        buttonContent: PropTypes.string,
        buttonUrl: PropTypes.string,
        newPage: PropTypes.bool
    }),
    scrollerBlocksList: PropTypes.arrayOf(PropTypes.shape({
        pretitle: PropTypes.string,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,   // Markdown.
        buttonBlock: PropTypes.shape({
            buttonContent: PropTypes.string.isRequired,
            buttonUrl: PropTypes.string.isRequired,
            newPage: PropTypes.bool
        }),
        image: PropTypes.shape({
            image: PropTypes.string.isRequired,
            imageAlt: PropTypes.string,
            imageTitle: PropTypes.string
        })
    })),
}

ScrollerBlocks.defaultProps = {
    buttonBlock: {},
    scrollerBlocksList: []
}
