import React from 'react'
import BgImage from '@components/atoms/BgImage'
import Container from '@components/atoms/Container'
import Image from '@components/atoms/Image'
import MarkdownField from '@components/atoms/MarkdownField'
import textProcessor from '@helpers/mikanTextSplitter.js'
import notchHelper from '@helpers/notches'
import PropTypes from 'prop-types'
import "./style.hero-block.scss";

const HeroBlock = ({
    title,
    contentOptional: content,
    imageOptional: image,
    backgroundImage,
    contentPosition,
    spacing,
    index,
    notches,
    lang
}) => {

    const contentClass = contentPosition ? `hudl-hero-block--content-${contentPosition}` : '';
    const hasImageClass = (image && image.image) ? 'hudl-hero-block--has-image' : '';
    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes = ['hudl-hero-block', contentClass, hasImageClass, marginTopClass, marginBottomClass];
    const bgImageClasses = [`hudl-hero-block__background-image`];

    const {NotchLayout} = notchHelper({notches, classArray: bgImageClasses})

    return (
        <div className={classes.join(" ")}>
            { notches && (
                <NotchLayout notchesOptions={notches} />
            ) }
            <BgImage className={bgImageClasses.join(" ")} imgsrc={backgroundImage} tag="div">
                <Container size="large">
                    {
                        (image && image.image) &&
                        (
                            <div className="hudl-hero-block__image">
                                <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} />
                            </div>
                        )
                    }
                    <div className={['hudl-hero-block__content'].join(' ')}>
                        {index === 0 && <h1 className="hudl-hero-block__heading hudl-alpha-type">{textProcessor(lang, title)}</h1>}
                        {index > 0 && <h2 className="hudl-hero-block__heading hudl-alpha-type">{textProcessor(lang, title)}</h2>}
                        { content && <MarkdownField lang={lang} content={content} /> }
                    </div>
                </Container>
            </BgImage>
        </div>
    );
};

export default HeroBlock;

HeroBlock.propTypes = {
    title: PropTypes.string.isRequired,
    contentOptional: PropTypes.string,   // Markdown.
    image: PropTypes.shape({
        image: PropTypes.string.isRequired,
        imageAlt: PropTypes.string,
        imageTitle: PropTypes.string
    }),
    backgroundImage: PropTypes.string,
    contentPosition: PropTypes.string
}

HeroBlock.defaultProps = {
    contentOptional: '',
    image: {
        image: '',
        imageTitle: '',
        imageAlt: ''
    },
    backgroundImage: '',
    contentPosition: 'center'
};