import React from 'react'
import BgImage from '@components/atoms/BgImage'
import Container from '@components/atoms/Container'
import Image from '@components/atoms/Image'
import textProcessor from '@helpers/mikanTextSplitter.js';
import notchHelper from '@helpers/notches'
import { detect } from 'detect-browser'
import PropTypes from 'prop-types'
/* eslint-disable */
import Quotes from '-!svg-react-loader!@images/hudl-quote.svg'
import ForwardSlash from '-!svg-react-loader!@images/hudl-forward-slash.svg'
import Slash4 from '-!svg-react-loader!@images/hudl-slash-4.svg'
/* eslint-enable */
import './style.quote-block.scss'

const QuoteBlock = ({
    quote,
    cite,
    imageOptional: image,
    backgroundImage,
    spacing,
    notches,
    lang
}) => {

    const browser = detect();

    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes = ["hudl-quote-block", `hudl-browser--${browser.name}`, !backgroundImage ? `hudl-padding-y--xxl hudl-quote-block--no-bg-img` : ``, marginTopClass, marginBottomClass];

    const bgImageClasses = [`hudl-quote-block__background`, backgroundImage ? `hudl-padding-y--xxl` : ``];

    const {NotchLayout} = notchHelper({notches, classArray: bgImageClasses})

    return (
        <div className={classes.join(" ")}>
            { notches && (
                <NotchLayout notchesOptions={notches} />
            ) }
            <BgImage className={bgImageClasses.join(" ")} imgsrc={backgroundImage} tag="div">
                <span className="hudl-quote-block__image-colour-overlay" />
                <Container>
                    <div className="hudl-quote-block__box-container">
                        <Slash4 className="hudl-quote-block__slash" />
                        <div className="hudl-quote-block__box">
                            <Quotes className="hudl-quote-block__quote-marks" />
                            {
                                image &&
                                (
                                    <div className="hudl-quote-block__box__image">
                                        {image.image && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} />}
                                    </div>
                                )
                            }
                            <div className="hudl-quote-block__box__text">
                                <blockquote className="hudl-gamma-type">{textProcessor(lang, quote)}</blockquote>
                                <cite>
                                    <ForwardSlash />
                                    <span>{textProcessor(lang, cite)}</span>
                                </cite>
                            </div>
                        </div>
                    </div>
                </Container>
            </BgImage>
        </div>
    )
}

export default QuoteBlock

QuoteBlock.propTypes = {
    quote: PropTypes.string.isRequired,
    cite: PropTypes.string.isRequired,   // Markdown.
    image: PropTypes.shape({
        image: PropTypes.string.isRequired,
        imageAlt: PropTypes.string,
        imageTitle: PropTypes.string
    }).isRequired,
    backgroundImage: PropTypes.string
}

QuoteBlock.defaultProps = {
    backgroundImage: null
};
