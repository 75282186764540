import getPrevPage from '@helpers/getPrevPage';
import { podId, munchkinId } from '@netlify/site-settings/marketo.yml'

const isBrowser = typeof window !== "undefined";

const initMarketoForms = ({ form, marketoReady, setMarketoReady, formReference }) => {

    const pushFormDataToDataLayer = (formEvent, formValues, getPrevPageValue) => {

        window.dataLayer.push({
            event: formEvent,
            content: formValues,
            url: window.document.URL,
            language: window.document.documentElement.lang,
            previous_page: getPrevPageValue
        });
    }

    const useEffectCallback = () => {
        // We need to wait for the form to load onto the page before we can do any dom manipulations.
        // We need to perform dom manipulations to remove the inline style attributes so that we can apply our styles.
        if (typeof MktoForms2 !== 'undefined' && isBrowser === true) {
            MktoForms2.whenReady((fm) => {
                const formEl = fm.getFormElem()[0];
                formEl.removeAttribute("style");
                Object.entries(formEl).forEach(element => {
                    if (element[1].style) {
                        element[1].removeAttribute("style");
                    }
                });
                fm.onSuccess ( (formValues) => {

                    pushFormDataToDataLayer ('form_submit', formValues, getPrevPage(window.dataLayer))
                    // TODO: Need to implement the Thank You page as this currently goes back to https://info.hudl.com.
                });
            })
        }
    }

    /* globals MktoForms2 */
    if (typeof MktoForms2 !== 'undefined' && marketoReady === false) {
        const loadForm = MktoForms2.loadForm.bind(MktoForms2, podId, munchkinId, form.formId);
        const formEl = formReference.current;

        (function loadFormCb(formEls) {  // eslint-disable-line no-shadow
            formEl.id = `mktoForm_${form.formId}`;
            loadForm();
        })(formEl);
        setMarketoReady(true);
    }

    return {useEffectCallback};

}

export default initMarketoForms
