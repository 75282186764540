// TODO: Swap out for loops for forEach loops.

function getCookie(name) {
    // eslint-disable-next-line prefer-template
    const value = "; " + document.cookie;
    // eslint-disable-next-line prefer-template
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        return true;
    }
    return false;
}

function reloadOTBanner() {
    if (!getCookie("OptanonAlertBoxClosed")) {
        const alertWrapper = document.getElementsByClassName(
            "optanon-alert-box-wrapper"
        );

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < alertWrapper.length; i++) {
            alertWrapper[i].remove();
        }

        const optMenu = document.getElementById("optanon-menu");
        if (optMenu) {
            optMenu.remove();
        }

        const optMain = document.getElementById("optanon");
        if (optMain) {
            optMain.remove();
        }

        const showSettings = document.getElementsByClassName("optanon-show-settings-popup-wrapper");
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < showSettings.length; i++) {
            showSettings[i].remove();
        }

        if (window.Optanon != null) {
            window.Optanon.Init();

            setTimeout(() => {
                window.Optanon.LoadBanner();

                const toggleDisplay = document.getElementsByClassName("optanon-toggle-display");

                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < toggleDisplay.length; i ++) {
                    toggleDisplay[i].onclick = (event) => {
                        event.stopImmediatePropagation();
                        window.Optanon.TriggerGoogleAnalyticsEvent(
                            "OneTrust Cookie Consent",
                            "Banner Open Preferences"
                        );
                        window.Optanon.ToggleInfoDisplay();
                    };
                }

                Array.from(document.getElementsByClassName("menu-item-about")).forEach(item => {
                    item.remove();
                });

            }, 1000);
        }
    }
}

export {reloadOTBanner, getCookie};
