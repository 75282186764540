import React, { useEffect, useRef, useState } from 'react'
import Button from "@components/atoms/Button"
import Container from '@components/atoms/Container'
import Image from '@components/atoms/Image'
import MarkdownField from '@components/atoms/MarkdownField'
import textProcessor from '@helpers/mikanTextSplitter.js';
import notchHelper from '@helpers/notches'
import Observer from '@researchgate/react-intersection-observer';
import PropTypes from 'prop-types'
import { StickyContainer, Sticky } from 'react-sticky';
import "./style.linked-content-block.scss";

const LinkedContentBlocks = ({
    title,
    content,
    contentBlocksList,
    pagePath,
    spacing,
    notches,
    lang
}) => {
    const [observationPaused, setObservationPaused] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const refs = useRef(contentBlocksList.map(() => React.createRef()));

    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes=["hudl-linked-content-block", "hudl-padding-y--xxl", "hudl-text-align--center", marginTopClass, marginBottomClass];

    const switchTab = (event, index) => {
        if (event.isIntersecting === true) {
            setCurrentIndex(index);
        }
    }

    const handleTabChange = (index) => {
        setObservationPaused(true);
        setCurrentIndex(index);
        refs.current[index].current.scrollIntoView({ block: 'center'});
    }

    useEffect(() => {
        if (observationPaused) {
            setTimeout(() => {
                setObservationPaused(false)
            }, 3000);
        }
    })

    // Create list of Content Block Nav items.
    const contentBlocksNavItems = contentBlocksList.map((item, index) => {
        let itemClass = 'hudl-linked-content-block__nav-item';

        if (index === currentIndex) {
            itemClass += ' hudl-linked-content-block__nav-item--active';
        }

        return (
            <li className={itemClass} key={index.toString()}>
                <Button className="hudl-linked-content-block__nav-item-link hudl-padding--md--mobile" onClick={() => handleTabChange(index)} title={item.title}>
                    <img className="hudl-margin-right--md hudl-linked-content-block__content-items-icon hudl-margin-right--sm" src={item.icon} alt="" />
                    {textProcessor(lang, item.title)}
                </Button>
            </li>
        )
    });

    // Create list of Content Block List items.
    const contentBlocksListItems = contentBlocksList.map((item, index) => {
        const hasItemButton = item.buttonBlock && item.buttonBlock.buttonUrl && item.buttonBlock.buttonContent;
        const hasImage = item.image && item.image.image;
        const threshold = [0.25, 0.5];

        return (
            <Observer disabled={observationPaused} rootMargin="-20% 0px -50% 0px" threshold={threshold} onChange={(event) => switchTab(event, index)}>
                <li className="hudl-linked-content-block__content-items hudl-padding-top--lg" key={index.toString()} ref={refs.current[index]}>
                    <div className="hudl-linked-content-block__content-items-content">
                        <div className="hudl-linked-content-block__content-items-title-row">
                            <img className="hudl-linked-content-block__content-items-icon" src={item.icon} alt="" />
                            <p className="hudl-gamma-type hudl-linked-content-block__content-items-title hudl-margin-bottom--xs">{textProcessor(lang, item.title)}</p>
                        </div>
                        <p className="hudl-linked-content-block__content-items-paragraph">{textProcessor(lang, item.paragraph)}</p>
                        { hasItemButton && <Button component="linked_content_blocks" variant={item.buttonBlock.buttonStyle} to={item.buttonBlock.buttonUrl} className="hudl-button" newPage={item.buttonBlock.newPage}>{textProcessor(lang, item.buttonBlock.buttonContent)}</Button> }
                    </div>
                    <div className="hudl-linked-content-block__content-items-image">
                        { hasImage && <Image imgsrc={item.image.image} title={item.image.imageTitle} alt={item.image.imageAlt} /> }
                    </div>
                </li>
            </Observer>
        )
    });

    const {NotchLayout} = notchHelper({notches, classArray: classes})

    return (
        <>
            <div className={classes.join(" ")}>
                { notches && (
                    <NotchLayout notchesOptions={notches} />
                ) }
                <Container size="large">
                    <h2 className="hudl-gamma-type hudl-linked-content-block__title">{textProcessor(lang, title)}</h2>
                    <MarkdownField content={content} className="hudl-padding-bottom--lg" />
                    <StickyContainer>
                        <Sticky topOffset={-115}>
                            {({style, isSticky}) => (
                                <ul style={{...style, marginTop: isSticky ? '115px' : 0}} className="hudl-linked-content-block__nav-items-list hudl-padding-bottom--lg">
                                    {contentBlocksNavItems}
                                </ul>
                            )}
                        </Sticky>
                        <ul className="hudl-linked-content-block__content-items-list">
                            {contentBlocksListItems}
                        </ul>
                    </StickyContainer>
                </Container>
            </div>
        </>
    )
}

export default LinkedContentBlocks

LinkedContentBlocks.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,   // Markdown.
    contentBlocksList: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,  // Image path.
        title: PropTypes.string.isRequired,
        paragraph: PropTypes.string.isRequired,
        buttonBlock: PropTypes.shape({
            buttonContent: PropTypes.string.isRequired,
            buttonUrl: PropTypes.string.isRequired,
            newPage: PropTypes.bool
        }),
        image: PropTypes.shape({
            image: PropTypes.string.isRequired,
            imageAlt: PropTypes.string,
            imageTitle: PropTypes.string
        })
    })),
}

LinkedContentBlocks.defaultProps = {
    contentBlocksList: []
}
