const pageUrlCleaner = ({path}) => {

    const splitPath = path.split('/');

    const hasLang = splitPath[0].length === 2;

    if (hasLang) {
        splitPath.shift();
    }

    const cleanPath = splitPath.join('/');

    return cleanPath;

}

export default pageUrlCleaner;