import React from 'react'
import Container from '@components/atoms/Container'
import MarketoForm from '@components/atoms/MarketoForm'
import textProcessor from '@helpers/mikanTextSplitter.js';
import PropTypes from 'prop-types'

import './style.form-image-block.scss'

const FormImageBlock = ({
    form,
    image,
    spacing,
    lang
}) => {

    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes = ["hudl-form-image-block", marginTopClass, marginBottomClass];

    return (
        <div className={classes.join(" ")} style={{backgroundImage: `url(${image.image})`}}>
            <div className="hudl-form-image-block__inner">
                <Container size="medium">
                    <div className="hudl-form-image-block__title hudl-form-image-block__content">
                        <h2 className="hudl-form-image-block__title hudl-beta-type">{textProcessor(lang, form.formTitle)}</h2>
                        <p className="hudl-form-image-block__intro hudl-margin-top--md hudl-margin-bottom--xl">{textProcessor(lang, form.formDescription)}</p>
                    </div>
                    <div className="hudl-form-image-block__form">
                        <MarketoForm form={form} />
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default FormImageBlock

FormImageBlock.propTypes = {
    form: PropTypes.shape({
        formTitle: PropTypes.string,
        formDescription: PropTypes.string,
        formId: PropTypes.number.isRequired
    }),
    image: PropTypes.shape({
        image: PropTypes.string.isRequired,
        imageAlt: PropTypes.string,
        imageTitle: PropTypes.string
    }),
}

FormImageBlock.defaultProps = {
    form: {},
    image: {}
}