import React from 'react'

let cmsCheck = false;

if (typeof window !== `undefined`) {
    // Check to see if ___gatsby element is present, if so then we are not within the CMS.
    const gatsbyWrapper = document.getElementById('___gatsby');
    cmsCheck = (gatsbyWrapper === null);
}

const IsCms = React.createContext(cmsCheck);
export default IsCms;