import React from 'react'
import Column from "@components/atoms/Column";
import Container from "@components/atoms/Container";
import Image from '@components/atoms/Image'
import LayoutContainer from "@components/atoms/LayoutContainer";
import ContentBlockText from "@components/molecules/ContentBlockText";

// eslint-disable-next-line
import HudlSlash1 from '-!svg-react-loader!@images/hudl-slash-1.svg'

import "./style.content-block.scss";

const ContentBlock = ({
    layout,
    title,
    pretitle,
    content,
    image,
    buttonBlockOptional: buttonBlock,
    alignment,
    spacing,
    index,
    fieldCount,
    lang
}) => {

    const hasImage = image && image.image;

    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes = ['hudl-content-block', `hudl-content-block--${layout}`, marginTopClass, marginBottomClass];

    const layoutPicker = () => {
        let output = null;

        switch(layout) {
        case 'layout-one':
            classes.push('hudl-padding-y--lg')
            output = [
                <div key={title-index} layout={layout} className={classes.join(' ')}>
                    <Container size="large">
                        <LayoutContainer imagePosition="left" columns="two" alignment={alignment}>
                            <Column>
                                <div className="hudl-slash">
                                    <HudlSlash1 className="hudl-slash__vector" />
                                    { hasImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
                                </div>
                            </Column>
                            <Column className="hudl-z-index__default">
                                <ContentBlockText
                                    pretitle={pretitle}
                                    title={title}
                                    content={content}
                                    buttonBlock={buttonBlock}
                                    trackingComponent="content_block_layout_one"
                                    lang={lang}
                                />
                            </Column>
                        </LayoutContainer>
                    </Container>
                </div>
            ];
            break
        case 'layout-two':
            classes.push('hudl-padding-y--lg')

            output = [
                <div key={title-index} layout={layout} className={classes.join(' ')}>
                    <LayoutContainer imagePosition="left" columns="two" alignment={alignment}>
                        <Column>
                            <Container size="small">
                                <ContentBlockText
                                    pretitle={pretitle}
                                    title={title}
                                    content={content}
                                    buttonBlock={buttonBlock}
                                    trackingComponent="content_block_layout_two"
                                    lang={lang}
                                />
                            </Container>
                        </Column>
                        <Column className="hudl-margin-bottom--none">
                            <Container size="medium">
                                {/* ToDo: We need to figure out a way to have normal full width / variable height images. */}
                                { hasImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
                            </Container>
                        </Column>
                    </LayoutContainer>
                </div>
            ];
            break
        case 'layout-three':
            classes.push('hudl-padding-y--lg')
            output = [
                <div key={title-index} layout={layout} className={classes.join(' ')}>
                    <Container size="small">
                        <LayoutContainer imagePosition="right" columns="two" alignment={alignment}>
                            <Column>
                                { hasImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
                            </Column>
                            <Column>
                                <div className="hudl-content-block__layout-three-content">
                                    <ContentBlockText
                                        pretitle={pretitle}
                                        title={title}
                                        titleSize="delta"
                                        content={content}
                                        buttonBlock={buttonBlock}
                                        trackingComponent="content_block_layout_three"
                                        lang={lang}
                                    />
                                </div>
                            </Column>
                        </LayoutContainer>
                    </Container>
                </div>
            ];
            break
        case 'layout-four':
            classes.push('hudl-padding-y--lg')
            classes.push('hudl-padding-bottom--xxl--mobile')
            output = [
                <div key={title-index} layout={layout} className={classes.join(' ')}>
                    <Container size="large" className="hudl-padding-x--none--mobile">
                        <LayoutContainer imagePosition="left" columns="two" alignment={alignment}>
                            {/* <Container size="medium"> */}
                            {/* ToDo: We need to figure out a way to have normal full width / variable height images. */}
                            { hasImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
                            {/* </Container> */}
                            <Container size="small" className="content-box">
                                <div className="content-box__inner">
                                    <ContentBlockText
                                        pretitle={pretitle}
                                        title={title}
                                        content={content}
                                        buttonBlock={buttonBlock}
                                        trackingComponent="content_block_layout_four"
                                        lang={lang}
                                    />
                                </div>
                            </Container>
                        </LayoutContainer>
                    </Container>
                </div>
            ];
            break
        case 'layout-five':
            classes.push('hudl-padding-y--xl--desktop')
            classes.push('hudl-padding-bottom--xxl')
            output = [
                <div key={title-index} layout={layout} className={classes.join(' ')}>
                    <Container size="large" className="hudl-padding-x--none--mobile">
                        <LayoutContainer imagePosition="left" columns="two" alignment={alignment}>
                            {/* <Container size="medium"> */}
                            {/* ToDo: We need to figure out a way to have normal full width / variable height images. */}
                            { hasImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
                            {/* </Container> */}
                            <Container size="small" className="content-box">
                                <div className="content-box__inner">
                                    <ContentBlockText
                                        pretitle={pretitle}
                                        title={title}
                                        content={content}
                                        buttonBlock={buttonBlock}
                                        trackingComponent="content_block_layout_five"
                                        lang={lang}
                                    />
                                </div>
                            </Container>
                        </LayoutContainer>
                    </Container>
                </div>
            ];
            break
        default:
            throw new Error(`Layout type ${layout} not found.`)
        }

        return output;
    }

    return (
        <>
            {layoutPicker(layout)}
        </>
    )
};

ContentBlock.defaultProps = {
    layout: "layout-one",
    buttonBlockOptional: {}
};

export default ContentBlock;
