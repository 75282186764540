import React, { useState }  from 'react'
import Button from '@components/atoms/Button'
import Column from '@components/atoms/Column';
import Container from '@components/atoms/Container'
import Image from '@components/atoms/Image'
import LayoutContainer from '@components/atoms/LayoutContainer';
import ContentBlockText from '@components/molecules/ContentBlockText';
import textProcessor from '@helpers/mikanTextSplitter.js';
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import './style.interactive-image-block.scss'

// ToDo: This component needs refactoring to only render the mobile or the desktop version
// depending what device the user is on. Currently we display both, and just hide one
// depending on what device the user is using, but this is bad for SEO and performance.
// We tried using React-Breakpoints, but couldn't get it working in time on all devices.

const InteractiveImageBlock = ({
    pretitle,
    title,
    contentOptional: content,
    buttonBlockOptional: buttonBlock,
    interactiveImageList,
    layout,
    spacing,
    lang
}) => {

    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';

    const hasButton = buttonBlock && buttonBlock.buttonUrl && buttonBlock.buttonContent;
    const classes = ["hudl-interactive-image", `hudl-interactive-image--${layout}`, "hudl-padding-y--xl", marginTopClass, marginBottomClass];
    const [activeImageButton, setActiveImageButton] = useState('image-0');

    const buttonClick = (e) => {
        e.preventDefault();
        const imageId = e.target.parentNode.id;
        setActiveImageButton(imageId);
    }

    const enterKeyPress = (e) => {
        if (e.keyCode === 13){
            const imageId = e.target.parentNode.id;
            setActiveImageButton(imageId);
        }
    }

    // Slides content for mobile layout.
    const interactiveImageSlides = interactiveImageList.map((
        {
            image,
            title: itemTitle,
            paragraph
        },
        index
    ) => {
        const hasSlideImage = image && image.image;
        return (
            <div key={index.toString()} className="hudl-interactive-image__image">
                { hasSlideImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
                <div className="hudl-interactive-image__slide-content hudl-margin-top--md hudl-padding-x--md">
                    <strong>{textProcessor(lang, itemTitle)}</strong>
                    <p>{textProcessor(lang, paragraph)}</p>
                </div>
            </div>
        )
    });

    // List of images for desktop layout.
    const interactiveImageImages = interactiveImageList.map(({ image }, index ) => {
        const hasImage = image && image.image;
        return (
            <li key={index.toString()} className={`hudl-interactive-image__image ${activeImageButton === `image-${index}` ? 'hudl-js-active' : '' }`}>
                {layout === 'layout-two' && (
                    <LayoutContainer columns="two">
                        <Column>
                            <ContentBlockText
                                pretitle={pretitle}
                                title={title}
                                content={content}
                                buttonBlock={buttonBlock}
                                lang={lang}
                            />
                        </Column>
                        <Column>
                            { hasImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
                        </Column>
                    </LayoutContainer>
                )}
                {layout === 'layout-one' && hasImage && (
                    <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} />
                )}
            </li>
        )
    });

    // Title and description text content for desktop.
    const interactiveImageDescriptions = interactiveImageList.map((
        {
            title: itemTitle,
            paragraph
        },
        index
    ) => {
        const interactiveImageDescriptionsClasses = ['hudl-interactive-image__image-button', activeImageButton === `image-${index}` ? 'hudl-js-active' : '']
        if (interactiveImageList.length !== index + 1){
            interactiveImageDescriptionsClasses.push('hudl-margin-bottom--sm');
        }
        return (
            // eslint-disable-next-line
            <li className={interactiveImageDescriptionsClasses.join(' ')} id={`image-${index}`} key={index.toString()}>
                {/* ToDo: These two elements should be children of a button and they keypress/onclick should be on the button not the li above. */}
                <button className="hudl-interactive-image__image-button-link" type="button" onClick={buttonClick} onKeyPress={enterKeyPress} href="/">
                    <strong className="hudl-interactive-image__image-button-title">{textProcessor(lang, itemTitle)}</strong>
                    <p className="hudl-interactive-image__image-button-paragraph">{textProcessor(lang, paragraph)}</p>
                </button>
            </li>
        )
    });

    const sliderSettings = {
        dots: true,
        speed: 500,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: true,
        centerPadding: '20px'
    };

    return (
        <div className={classes.join(' ')}>
            <Container>

                <div className="hudl-interactive-image-container hudl-interactive-image__mobile">
                    <ContentBlockText
                        pretitle={pretitle}
                        title={title}
                        content={content}
                        buttonBlock={buttonBlock}
                        lang={lang}
                    />

                    <Slider className="hudl-interactive-image__slider" {...sliderSettings}>
                        {interactiveImageSlides}
                    </Slider>
                </div>

                <div className="hudl-interactive-image-container hudl-interactive-image__desktop">
                    <div className="hudl-interactive-image__inner">
                        <div className="hudl-interactive-image__image-list">
                            { layout === "layout-one" && (
                                <>
                                    <ContentBlockText
                                        lang={lang}
                                        pretitle={pretitle}
                                        title={title}
                                        content={content}
                                    />
                                </>
                            )}

                            <ul className="hudl-interactive-image__image-button-list">
                                {interactiveImageDescriptions}
                            </ul>

                            { hasButton && layout === "layout-one" && <Button component="interactive_image_block_layout_one" variant={buttonBlock.buttonStyle} to={buttonBlock.buttonUrl} className="hudl-button" newPage={buttonBlock.newPage}>{textProcessor(lang, buttonBlock.buttonContent)}</Button> }

                            { hasButton && layout === "layout-two" && (
                                <div className="hudl-text-align--center hudl-margin-top--xl">
                                    <Button component="interactive_image_block_layout_two" variant={buttonBlock.buttonStyle} to={buttonBlock.buttonUrl} className="hudl-button" newPage={buttonBlock.newPage}>{textProcessor(lang, buttonBlock.buttonContent)}</Button>
                                </div>
                            )}

                        </div>
                        <div className="hudl-interactive-image__images">
                            <ul className="hudl-interactive-image__images-list">
                                {interactiveImageImages}
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default InteractiveImageBlock

InteractiveImageBlock.propTypes = {
    pretitle: PropTypes.string.isRequired,
    title: PropTypes.string,
    contentOptional: PropTypes.string,   // Markdown.
    interactiveImageList: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.shape({
            image: PropTypes.string,
            imageAlt: PropTypes.string,
            imageTitle: PropTypes.string
        }),
        title: PropTypes.string,
        paragraph: PropTypes.string,
    })),
    layout: PropTypes.string.isRequired,
}

InteractiveImageBlock.defaultProps = {
    title: '',
    contentOptional: '',
    interactiveImageList: [],
};
