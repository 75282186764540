const fontSetting = (lang) => {
    switch (lang) {
    case 'en':
        return [
            {
                font: 'Open Sans',
                weights: [300, 400, 500, 600],
            }
        ]
    case 'ja':
        return [
            {
                font: 'Noto Sans JP',
                weights: [100, 400, 700],
            }
        ]
    default:
        return []
    }
}

export default fontSetting;
