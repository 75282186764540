import React from 'react'
import Container from "@components/atoms/Container"
import PropTypes from 'prop-types'
import Slider from "react-slick"

import "./style.logo-strip.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoStrip = ({ imageMultiple: images, spacing }) => {

    // Netlify CMS required validation does not work on nested list items,
    // see -> https://github.com/netlify/netlify-cms/issues/467
    // So, it is possible to successfully save a LogoStrip block in the
    // CMS with zero images for this component.
    // Prevent errors by returning null if image array is empty in error.
    if (!Array.isArray(images)) {
        return null;
    }

    // Only the bottom margin has been implemented due to the LogoStrip been used on the Homepage
    // and it is pulled up into the Hero.
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes = ['hudl-logo-strip', marginBottomClass];

    const settings = {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 7,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 440,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    const logoItems = images.map(({ image }, i) =>  {
        return <div className="hudl-padding-y--sm" key={image.image}><img src={image.image} title={image.imageTitle} alt={image.imageAlt} /></div>
    });

    return (
        <Container>
            <div className={classes.join(" ")}>
                <Slider {...settings}>
                    {logoItems}
                </Slider>                
            </div>
        </Container>
    );
};

export default LogoStrip;

LogoStrip.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.shape({
            image: PropTypes.string.isRequired,
            imageAlt: PropTypes.string,
            imageTitle: PropTypes.string
        })
    }))
}

LogoStrip.defaultProps = {
    images: []
};
