import React from "react";
import Container from "@components/atoms/Container";
import ContentBlockText from '@components/molecules/ContentBlockText';

import "../../../sass/config/_layout.scss";
import "./style.one-column-text.scss";
import "../../../sass/variables/_align-items.scss";

const OneColumnText = ({
    content,
    buttonBlockOptional: buttonBlock,
    textAlign,
    spacing,
    lang
}) => {

    const marginTopClass = (spacing && spacing.marginTop) ? 'hudl-block__margin-top' : '';
    const marginBottomClass = (spacing && spacing.marginBottom) ? 'hudl-block__margin-bottom' : '';
    const classes = ['hudl-one-column-text', 'hudl-padding-y--lg', `hudl-text-align--${textAlign}`, marginTopClass, marginBottomClass];

    return (
        <div className={classes.join(" ")}>
            <Container size="small">
                <ContentBlockText
                    lang={lang}
                    content={content}
                    buttonBlock={buttonBlock}
                    trackingComponent="one_column_text"
                />
            </Container>
        </div>
    );
};

OneColumnText.defaultProps = {
    type: "one-column-text"
};

export default OneColumnText;

// Example Usage
// <OneColumnText
//     textAlign="center"
//     content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat odio in nisl blandit luctus nec in neque. Maecenas dignissim purus at pellentesque feugiat."
// />
