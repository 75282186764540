import React from 'react';
import textProcessor from '@helpers/mikanTextSplitter';

const ColonFix = ({string, lang}) => {

    const addColons = (theParts) => {
        let result = null;
        if (theParts.length > 0) {
            result = theParts.map((substring, index, array) => (
                <span key={`key-${substring.toLowerCase()}`}>
                    {index > 0 && (
                        <span className="hudl-colon-fix">:</span>
                    )}
                    <span>{textProcessor(lang, substring)}</span>
                </span>
            ));
        }
        if (theParts.length === 0) {
            result = textProcessor(lang, string);
        }

        return  result;
    }

    return (
        <>
            {addColons(string.split(':'))}
        </>
    );
}

export default ColonFix;
