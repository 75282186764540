import React from "react";

import "./style.layout-container.scss";
import "../../../sass/config/_layout.scss";
import "../../../sass/variables/_align-items.scss";

const LayoutContainer = ({
    reverseColumns = false,
    children = false,
    columns = false,
    alignment = false
}) => {

    const cols = [columns ? `hudl-layout-container--${columns}-columns` : ''];
    const reverse = [reverseColumns === "true" ? `hudl-layout-container--reverse-columns` : ''];
    const align = [alignment ? `hudl-alignment--${alignment}` : ''];
    const classes = ['hudl-layout-container', cols, reverse, align];
    return (
        <div className={classes.join(" ")}>
            {children}
        </div>
    );
};

LayoutContainer.defaultProps = {
    type: "layout-container"
};

export default LayoutContainer;
